import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import { Rating } from "@mui/material";

// Transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUpdateReview = (props) => {
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    review: "",
    stars: "",
    vendor_id: null,
    user_id: null,
  });

  // State to store the dropdown options
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);

  const resetForm = () => {
    setFormData({
      review: "",
      stars: "",
      vendor_id: null,
      user_id: null,
    });
  };

  const getUsersAndVendors = () => {
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/getCustomersForAdminReview`
      )
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        toast.error("Error geting users");
      });

    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getVendorsForAdminReview`)
      .then((response) => {
        setVendors(response.data);
      })
      .catch((error) => {
        toast.error("Error geting vendors");
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/mobile/vendorReviews/addByAdmin`,
      data: formData,
    })
      .then((response) => {
        toast.success("Inserted successfully");
        setSaveIsLoading(false);
        props.onClose(true);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error("Error while saving");
      });
  };

  // Handle input changes (both text and dropdown)
  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [id]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    let { id, value, innerText } = e.target;
    if (!id) return;
    value = id.includes("option") ? innerText : value;
    id = id.includes("mui") ? "stars" : id;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  useEffect(() => {
    getUsersAndVendors();
    resetForm();
  }, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Add Review</h4>
        </Toolbar>
      </AppBar>

      <div style={{ padding: "25px" }}>
        <div className="row">
          {/* Review Input */}
          <div className={"col-sm-6"}>
            <div className="form-group">
              <label htmlFor="review">Review</label>
              <input
                type="text"
                className="form-control"
                id="review"
                onChange={handleInputChange}
                value={formData.review}
              />
            </div>
          </div>

          {/* User Dropdown */}
          <div className={"col-sm-6"}>
            <div className="form-group">
              <label htmlFor="user_id">User</label>
              <select
                id="user_id"
                className="form-control"
                onChange={handleInputChange}
                value={formData.user_id || ""}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Vendor Dropdown */}
          <div className={"col-sm-6"}>
            <div className="form-group">
              <label htmlFor="vendor_id">Vendor</label>
              <select
                id="vendor_id"
                className="form-control"
                onChange={handleInputChange}
                value={formData.vendor_id || ""}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.first_name} {vendor.last_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={"col-sm-6"}>
            <div>
              <label htmlFor="name">Rating</label>
            </div>
            <div>
              <Rating
                size="large"
                id="stars"
                name="simple-controlled"
                value={formData.stars}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Save Button */}
          <div className={"col-sm-12 mt-5"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button
                className="btn btn-md btn-primary float-end"
                onClick={saveForm}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddUpdateReview;

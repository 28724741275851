import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
// import AdminLayout from "./AdminLayout";
import AdminLayout from "../AdminLayout";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import AddUpdateReview from "./AddUpdateReview";

const AdminVendorReviews = () => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(-1);

    const getData = () => {
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API}/mobile/vendorReviews/getAll`, {})
            .then(function (response) {
                setData(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                toast("Error", {
                    position: "top-right",
                    type: "error",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                console.log(error);
            });
    };

    const addForm = () => {
        setId(-1);
        setVisible(true);
    };

    const updateFrom = (meta) => {
        console.log(meta);

        const id = meta.rowData[0];
        setId(id);
        setVisible(true);
    };
    const handleClose = (refresh) => {
        setVisible(false);
        if (refresh) {
            getData();
        }
    }

    const deleteForm = (meta) => {
        const id = meta.rowData[0];
        Swal.fire({
            title: "Please Confirm Your Action ?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            icon: "warning",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API}/mobile/vendorReviews/delete`,
                    data: { id: id },
                })
                    .then((response) => {
                        toast.success(`Success`);
                        getData();
                    })
                    .catch((error) => {
                        toast.error(`Error`);
                    });
            }
        });
    };
    const acceptReview = (meta) => {
        const id = meta.rowData[0];
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/mobile/vendorReviews/acceptReview`,
            data: { id: id },
        })
            .then((response) => {
                toast.success(`Success`);
                getData();
            })
            .catch((error) => {
                toast.error(`Error`);
            });
    }

    const columns = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "active",
            label: "Active",
            options: {
                customBodyRender: (value) => {
                    return (
                        <input
                            disabled
                            className="form-check-input"
                            type="checkbox"
                            readOnly
                            checked={value == 1 ? true : false}
                        />
                    );
                },
            },
        },
        {
            name: "vendor_name",
            label: "Vendor",
        },
        {
            name: "user_name",
            label: "User",
        },
        {
            name: "review",
            label: "Review",
        },
        {
            name: "stars",
            label: "From 0 to 5",
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, meta) => {
                    return (
                        <div>
                            {/* <button
                                className="btn btn-primary mx-1"
                                onClick={() => {
                                    updateFrom(meta);
                                }}
                            >
                                Update
                            </button> */}
                            <button
                                className="btn btn-danger mx-1"
                                onClick={() => {
                                    deleteForm(meta);
                                }}
                            >
                                Delete
                            </button>
                            {
                                meta.rowData[1] == 1 ? null :
                                    <button
                                        className="btn btn-success mx-1"
                                        onClick={() => {
                                            acceptReview(meta);
                                        }}
                                    >
                                        Accept
                                    </button>
                            }
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        filter: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [20, 50, 100],
        selectableRows: "none",
        customToolbar: () => {
            return (
                <span>
                    <Tooltip title={"Add"}>
                        <IconButton onClick={addForm}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        },
        textLabels: {
            body: {
                noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
            },
        },
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <AdminLayout>
            <div className="container">
                <CustomMuiDataTable title={"Vendor Reviews"} data={data} columns={columns} options={options} />
            </div>
            <AddUpdateReview open={visible} onClose={handleClose} id={id} />
        </AdminLayout>
    );
};
export default AdminVendorReviews;
